<template>
  <div class="home flex-box">
    <div class="home-left">
      <div @click="$router.push({name:'schoolMessage'})"
           class="school-box">学校信息</div>
      <div class="schoolname">{{schoolMessageForm.sname}}</div>
      <p>学校理念：</p>
      <div class="schooltext">{{schoolMessageForm.schoolmotto}}</div>
      <p>学校简介：</p>
      <div class="schooltext">{{schoolMessageForm.schooltext}}</div>
    </div>
    <div class="home-right">
      <div class="home-right-top flex-box">
        <div class="banner">
          <el-tabs v-model="bannerActiveName"
                   type="card">
            <!-- <el-tab-pane label="名师风采"
              name="first">
              <div class="block">
                <el-carousel trigger="click"
                  height="150px">
                  <el-carousel-item v-for="item in 4"
                    :key="item">
                    <h3 class="small">{{ item }}</h3>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-tab-pane> -->
            <!-- <el-tab-pane label="学习之星"
              name="second">学习之星</el-tab-pane> -->
            <el-tab-pane label="活动动态"
                         class="third-box"
                         name="third"><img src="../assets/images/banner2.png"
                   height="163px"></el-tab-pane>
          </el-tabs>

        </div>
        <div class="notice">
          <div class="notice-title flex-box">
            <span>通知公告</span>
            <span @click="$router.push({name:'notice'})">更多></span>
          </div>
          <ul>
            <li v-for="notice in allNotice"
                :key="notice.id"
                @click="viewNotice(notice)"
                class="notice-item flex-box">
              <span>{{notice.title}}</span>
              <span>{{notice.opertime}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="home-right-bottom">
        <div @click="$router.push({name:'timeTableManage'})"
             class="time"> 个人课表 >>></div>
        <table cellspacing="0"
               border="1"
               cellpadding="15"
               class="table-box">
          <thead>
            <tr>
              <th>节课/星期</th>
              <th>星期一</th>
              <th>星期二</th>
              <th>星期三</th>
              <th>星期四</th>
              <th>星期五</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="time in timeList"
                :key="time.sectionIndex">
              <td>{{time.section}}</td>
              <td v-for="(course,index) in time.course"
                  :key="index"
                  :style="courseColor(course)"
                  :colspan="time.course.length===1 ? '5' : '1'">
                <p>{{course}}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
  data() {
    return {
      bannerActiveName: "third",
      classNameList: [],
      timeList: [
        { section: "第一节(8:30-8:45)", sectionIndex: "1", course: [] },
        {
          section: "(8:45-9:35)",
          sectionIndex: "7",
          course: ["大课间（阳光体育）"],
        },
        { section: "第二节(9:35-10:10)", sectionIndex: "2", course: [] },
        { section: "第三节(10:20-10:55)", sectionIndex: "3", course: [] },
        { section: "第四节(11:05-11:40)", sectionIndex: "4", course: [] },
        {
          section: "(11:40-14:40)",
          sectionIndex: "8",
          course: ["午餐午休"],
        },
        { section: "第五节(14:40-15:15)", sectionIndex: "5", course: [] },
        { section: "第六节(15:25-16:00)", sectionIndex: "6", course: [] },
      ],
      schoolMessageForm: {},
      allNotice: [],
      page: {
        pageCount: 0,
        currentPage: 1,
      },
      backgroundColorList: [
        "#fff",
        "#0061ae",
        "#4574c6",
        "#198cf5",
        "#8dbafe",
        "#e84c45",
        "#d27331",
        "#e5c22a",
        "#ee5873",
        "#ff87b7",
        "#0d873c",
        "#84dc1e",
        "#582fcd",
        "#8459fd",
        "#b89bff",
        "#20b5b3",
      ],
    };
  },
  created() {
    this.teacherSchedule();
    this.schoolMessage();
    this.loadAllNotice(1);
  },
  methods: {
    schoolMessage() {
      const that = this;
      this.$post("/media/querySchool.do", {})
        .then((res) => {
          that.schoolMessageForm = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            $message: err.message,
          });
        });
    },
    teacherSchedule() {
      const that = this;
      this.$post("/base/queryCourseTableByTea.do", {})
        .then((res) => {
          res.data.forEach((resDay) => {
            that.timeList.forEach((time) => {
              if (resDay.day === time.sectionIndex) {
                let course = [];
                resDay.value.forEach((lesson) => {
                  if (lesson.split("-")[0] !== "") {
                    that.classNameList.push(lesson.split("-")[0]);
                  }
                  course.push(
                    `${lesson.split("-")[0]}-${lesson.split("-")[1]}`
                  );
                });
                time.course = course;
              }
            });
          });
          that.classNameList = [...new Set(that.classNameList)];
        })
        .catch((err) => {
          that.$message.error(err.message);
        });
    },
    loadAllNotice(page) {
      const that = this;
      this.$post("/base/queryAllNotice.do", { name: "", indexNo: page })
        .then((res) => {
          that.page.currentPage = page;
          that.page.pageCount = res.data.pageCount;
          for (let i = 0; i < res.data.rows.length; i++) {
            that.allNotice.push(res.data.rows[i]);
            if (i >= 5) {
              return;
            }
          }
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    viewNotice(notice) {
      this.$router.push({
        name: "noticePage",
        query: {
          noticeTitle: notice.title,
          noticeOperName: notice.opername,
          noticeOperTime: notice.opertime,
          noticeContent: notice.content,
          noticeReceiver: notice.mname,
        },
      });
    },
    courseColor(course) {
      const index = this.classNameList.indexOf(course.split("-")[0]);

      return index === -1
        ? ""
        : `background-color:${this.backgroundColorList[index]};color:#fff`;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  align-items: flex-start;

  &-left {
    width: 300px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-right: 20px;
    background-color: #ffffff;
  }

  &-right {
    flex: 1;
  }

  &-right-top {
    > div {
      width: 50%;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      padding: 20px;
      background-color: #ffffff;
    }

    .banner {
      margin-right: 20px;
    }
  }
  .school-box {
    margin-bottom: 15px;
  }
  .school-box:hover {
    cursor: pointer;
    color: #999999;
  }
  .notice {
    &-title > span:nth-child(2) {
      font-size: 14px;
      cursor: pointer;
    }

    &-title > span:nth-child(2):hover {
      color: #999999;
    }

    &-item {
      cursor: pointer;
      margin-top: 10px;
      font-size: 15px;
    }

    &-item:hover {
      color: #999999;
    }
  }

  &-right-bottom {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
    background-color: #ffffff;
  }
}
.time {
  margin-bottom: 15px;
}
.time:hover {
  cursor: pointer;
  color: #999999;
}
.table-box {
  text-align: center;
  width: 100%;
}
.table-box td {
  padding: 10px;
}

.table-box p {
  margin: 5px 0;
}
.schooltext {
  text-indent: 25px;
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}
.schoolname {
  margin-bottom: 15px;
  font-size: 22px;
  text-align: center;
}
/deep/ .el-tabs__content {
  height: 163px;
}
.notice > ul {
  height: 163px;
}
.third-box {
  text-align: center;
}
.notice-item > span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
</style>
